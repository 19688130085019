@import url('https://fonts.googleapis.com/css2?family=Alata&display=swap');

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;
}

ul,
ol {
    list-style: none;
}

// body {
//     font-family: 'Alata';
// }

/* variables */
$wave-bg : #008037;


/* mixins */
@mixin flex($t, $j, $a) {
    display: flex;
    flex-flow: $t nowrap;
    justify-content: $j;
    align-items: $a;
}

/* styles */
.container {
    display: block;
}

.parent {
    width: 100%;
    height: 100vh;
    @include flex(row, center, center);
}

.wave-card {
    width: 250px;
    height: 300px;
    // @include flex(column, end, center);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    box-shadow: 11px 17px 48px #e5e5e5;
    border-radius: 10px;
    overflow: hidden;

    &-shape {
        position: absolute;
        width: 400px;
        height: 400px;
        z-index: 1;
        opacity: 0.6;
        bottom: -250px;
        left: -100px;
        border-radius: 150px;
        background: $wave-bg;
        animation: rotate 30s linear infinite;
    }

    &-shape:nth-child(1) {
        bottom: -245px;
        left: -98px;
        opacity: 0.4;
        z-index: 2;
        animation: rotate 25s linear infinite;
    }

    &-shape:nth-child(2) {
        bottom: -240px;
        opacity: 0.3;
        z-index: 3;
        animation: rotate 32s linear infinite;
    }

    &-title {}

    &-subtitle {
        // margin-bottom: 0px;
    }
}


@keyframes rotate {
    from {
        transform: rotate(0)
    }

    to {
        transform: rotate(1turn);
    }
}

// mobile styles

@media (max-width: 768px) {
    .parent {
        @include flex(column, center, center);
    }

    .wave-card {
        width: 100%;
        height: auto;

        &-shape {
            width: 100%;
            height: 30%;
            bottom: 0;
            left: 0;
            border-radius: 0;
            animation: none;
        }
    }
}