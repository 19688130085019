@import url("https://fonts.googleapis.com/css2?family=Alata&display=swap");
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

ul,
ol {
  list-style: none;
}

/* variables */
/* mixins */
/* styles */
.container {
  display: block;
}

.parent {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.wave-card {
  width: 250px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  box-shadow: 11px 17px 48px #e5e5e5;
  border-radius: 10px;
  overflow: hidden;
}
.wave-card-shape {
  position: absolute;
  width: 400px;
  height: 400px;
  z-index: 1;
  opacity: 0.6;
  bottom: -250px;
  left: -100px;
  border-radius: 150px;
  background: #008037;
  -webkit-animation: rotate 30s linear infinite;
          animation: rotate 30s linear infinite;
}
.wave-card-shape:nth-child(1) {
  bottom: -245px;
  left: -98px;
  opacity: 0.4;
  z-index: 2;
  -webkit-animation: rotate 25s linear infinite;
          animation: rotate 25s linear infinite;
}
.wave-card-shape:nth-child(2) {
  bottom: -240px;
  opacity: 0.3;
  z-index: 3;
  -webkit-animation: rotate 32s linear infinite;
          animation: rotate 32s linear infinite;
}
@-webkit-keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}
@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}
@media (max-width: 768px) {
  .parent {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
  .wave-card {
    width: 100%;
    height: auto;
  }
  .wave-card-shape {
    width: 100%;
    height: 30%;
    bottom: 0;
    left: 0;
    border-radius: 0;
    -webkit-animation: none;
            animation: none;
  }
}/*# sourceMappingURL=wave.css.map */