/* 
  Pure CSS Rainbows - Part of Project Rainbow
  https://csee.bangor.ac.uk/category/project-rainbow/
*/
/* Variables (Play with these) */
/* Leave these alone */
.rainbow {
  width: 300px;
  height: 150px;
  margin: 0px;
  position: relative;
  overflow: hidden;
}
.rainbow.bands::before {
  content: "";
  position: absolute;
  width: 300px;
  height: 300px;
  filter: hue-rotate(-15deg) contrast(95%);
  opacity: 1;
  border-radius: 50%;
  background: radial-gradient(circle at center, transparent, transparent 90px, #9400D3 90px, #9400D3 90px, #9400D3 98.5714285714px, #4B0082 98.5714285714px, #4B0082 107.1428571429px, #0000FF 107.1428571429px, #0000FF 115.7142857143px, #00FF00 115.7142857143px, #00FF00 124.2857142857px, #FFFF00 124.2857142857px, #FFFF00 132.8571428571px, #FF7F00 132.8571428571px, #FF7F00 141.4285714286px, #FF0000 141.4285714286px, #FF0000 150px);
}
.rainbow.smooth::before {
  content: "";
  position: absolute;
  width: 300px;
  height: 300px;
  filter: hue-rotate(-15deg) contrast(95%);
  opacity: 1;
  border-radius: 50%;
  background: radial-gradient(circle at center, transparent, transparent 90px, #9400D3 90px, #9400D3 90px, #4B0082 99.7959183673px, #0000FF 109.5918367347px, #00FF00 119.387755102px, #FFFF00 129.1836734694px, #FF7F00 138.9795918367px, #FF0000 148.7755102041px);
}
.rainbow::after {
  content: "";
  position: absolute;
  transform-origin: 150px 150px;
  width: 300px;
  height: 150px;
  background-color: #DCFCE7;
  -webkit-animation: wipe 6s ease-in-out infinite;
          animation: wipe 6s ease-in-out infinite;
}

@-webkit-keyframes wipe {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(180deg);
  }
  70% {
    transform: rotate(180deg);
  }
  90% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes wipe {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(180deg);
  }
  70% {
    transform: rotate(180deg);
  }
  90% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}/*# sourceMappingURL=rainbow.css.map */