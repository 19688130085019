*,
*:after,
*:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: '';
}

.clearfix:after {
    clear: both;
}


/* body {
	background: #ffffff;
	color: #333;
	font-weight: normal;
	font-size: 1em;
	font-family: 'Roboto', Arial, sans-serif;
} */

input:focus,
textarea:focus,
keygen:focus,
select:focus {
    outline: none;
}

::-moz-placeholder {
    color: #666;
    font-weight: 300;
    opacity: 1;
}

::-webkit-input-placeholder {
    color: #666;
    font-weight: 300;
}


/* Contact Form Styling */
.containerrr {
    padding: 0 50px 70px;
}

.textcenter {
    text-align: center;
}

.section1 {
    text-align: center;
    display: table;
    width: 100%;
}

.section1 .shtext {
    display: block;
    margin-top: 20px;
}

.section1 .seperator {
    border-bottom: 1px solid #a2a2a2;
    width: 35px;
    display: inline-block;
    margin: 20px;
}

.section1 h1 {
    font-size: 40px;
    color: #008037;
    font-weight: normal;
}

.section2 {
    width: 1200px;
    margin: 25px auto;
}

.section2 .col2 {
    width: 48.71%;
}

.section2 .col2.first {
    float: left;
}

.section2 .col2.last {
    float: right;
}

.section2 .col2.column2 {
    padding: 0 30px;
}

.section2 span.collig {
    color: #a2a2a2;
    margin-right: 10px;
    display: inline-block;
}

.section2 .sec2addr {
    display: block;
    line-height: 26px;
}

.section2 .sec2addr p:first-child {
    margin-bottom: 10px;
}

.section2 .sec2contactform input[type="text"],
.section2 .sec2contactform input[type="email"],
.section2 .sec2contactform textarea {
    padding: 18px;
    border: 0;
    background: #EDEDED;
    margin: 7px 0;
}

.section2 .sec2contactform textarea {
    width: 100%;
    display: block;
    color: #666;
    resize: none;
}

.section2 .sec2contactform input[type="submit"] {
    padding: 15px 40px;
    color: #fff;
    border: 0;
    background: #008037;
    font-size: 16px;
    text-transform: uppercase;
    margin: 7px 0;
    cursor: pointer;
}

.section2 .sec2contactform h3 {
    font-weight: normal;
    margin: 20px 0;
    margin-top: 30px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 19px;
    color: #008037;
}

/* @media querries */

@media only screen and (max-width: 1266px) {
    .section2 {
        width: 100%;
    }
}

@media only screen and (max-width: 960px) {
    .containerrr {
        padding: 0 30px 70px;
    }

    .section2 .col2 {
        width: 100%;
        display: block;
    }

    .section2 .col2.first {
        margin-bottom: 10px;
    }

    .section2 .col2.column2 {
        padding: 0;
    }

    body .sec2map {
        height: 250px !important;
    }
}

@media only screen and (max-width: 768px) {
    .section2 .sec2addr {
        font-size: 14px;
    }

    .section2 .sec2contactform h3 {
        font-size: 16px;
    }

    .section2 .sec2contactform input[type="text"],
    .section2 .sec2contactform input[type="email"],
    .section2 .sec2contactform textarea {
        padding: 10px;
        margin: 3px 0;
    }

    .section2 .sec2contactform input[type="submit"] {
        padding: 10px 30px;
        font-size: 14px;
    }
}

@media only screen and (max-width: 420px) {
    .section1 h1 {
        font-size: 28px;
    }
}