/* 
  Pure CSS Rainbows - Part of Project Rainbow
  https://csee.bangor.ac.uk/category/project-rainbow/
*/

/* Variables (Play with these) */
$rainbow-diameter: 300px;
$rainbow-thickness: 60px;
$animation-duration: 6s;
$background-colour: #DCFCE7;
$rainbow-filter: hue-rotate(-15deg) contrast(95%);
$rainbow-opacity: 1;
$rainbow-colours: #FF0000, // red
    #FF7F00, // orange
    #FFFF00, // yellow
    #00FF00, // green
    #0000FF, // blue
    #4B0082, // indigo
    #9400D3; // violet

/* Leave these alone */
$rainbow-radius: $rainbow-diameter / 2;
$colour-band-width: $rainbow-thickness / length($rainbow-colours);

// Generate a rainbow gradient
@mixin rainbowGradient($type) {

    // Base gradient
    $rainbow-gradient: circle at center,
    transparent,
    transparent $rainbow-radius - $rainbow-thickness,
    nth($rainbow-colours, -1) $rainbow-radius - $rainbow-thickness;

    // Generate banded or smooth radial gradient
    @for $i from 1 through length($rainbow-colours) {

        // Next colour
        $colour: nth($rainbow-colours, -$i);

        // Start of band
        $rainbow-gradient: append($rainbow-gradient,
            $colour $rainbow-radius - ($rainbow-thickness - if($type =="smooth",
                    // Smooth: Offset the band start by half a band for better blending
                    $colour-band-width + ($colour-band-width / (length($rainbow-colours))),
                    // Bands: Do not offset the band
                    $colour-band-width ) * ($i - 1)));

        // End of band (only use this for type == band)
        @if($type =="bands") {
            $rainbow-gradient: append($rainbow-gradient,
                $colour $rainbow-radius - ($rainbow-thickness - $colour-band-width * $i));
        }
    }

    background: radial-gradient($rainbow-gradient);
}

// the rainbow element
@mixin rainbow($type) {
    &::before {
        content: '';
        position: absolute;
        width: $rainbow-diameter;
        height: $rainbow-diameter;
        filter: $rainbow-filter;
        opacity: $rainbow-opacity;
        border-radius: 50%;
        @include rainbowGradient($type);
    }
}

// Rainbow container
.rainbow {
    width: $rainbow-diameter;
    height: $rainbow-radius;
    margin: 0px;
    position: relative;
    overflow: hidden;

    // Rainbow classes
    &.bands {
        @include rainbow("bands");
    }

    &.smooth {
        @include rainbow("smooth");
    }

    // Animation element
    &::after {
        content: '';
        position: absolute;
        transform-origin: $rainbow-radius $rainbow-radius;
        width: $rainbow-diameter;
        height: $rainbow-radius;
        background-color: $background-colour;
        animation: wipe $animation-duration ease-in-out infinite;
    }
}

// Wipe animation
@keyframes wipe {
    0% {
        transform: rotate(0deg);
    }

    20% {
        transform: rotate(180deg);
    }

    70% {
        transform: rotate(180deg);
    }

    90% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(360deg);
    }
}