/* tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dbg {
  background:
    -webkit-linear-gradient(rgba(135, 60, 255, 1), rgba(135, 60, 255, 0.0) 0%),
    -webkit-linear-gradient(-45deg, rgba(255, 255, 255, 0.9) 12%, #13A25D 0%);
}

.divider__1 {
  height: 1px;
  background-color: white;
  width: 30px;
}

.divider__2 {
  height: 0.5px;
  background-color: white;
  width: 30px;
}

/* dropdown */

.Item_item__azkIp {
  font-size: normal;
}

.Item_item__azkIp:hover {
  background-color: #c9f8e1;
}

/* dropdown end */

/* divider */

.astrodivider {
  margin: 64px auto;
  width: 400px;
  max-width: 100%;
  position: relative;
}

.astrodividermask {
  overflow: hidden;
  height: 20px;
}

.astrodividermask:after {
  content: '';
  display: block;
  margin: -25px auto 0;
  width: 100%;
  height: 25px;
  border-radius: 125px / 12px;
  box-shadow: 0 0 8px #008037;
}

.astrodivider span {
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 100%;
  margin-bottom: -25px;
  left: 50%;
  margin-left: -25px;
  border-radius: 100%;
  box-shadow: 0 2px 4px #008037;
  background: #fff;
}

.astrodivider i {
  position: absolute;
  top: 4px;
  bottom: 4px;
  left: 4px;
  right: 4px;
  border-radius: 100%;
  border: 1px dashed #008037;
  text-align: center;
  line-height: 40px;
  font-style: normal;
  color: #008037;
}

/* divider end */